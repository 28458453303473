<template>
  <div>
    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.tax') }}</label>
                <multiselect v-model="tax"
                             :placeholder="$t('tax_report.tax')"
                             label="name"
                             track-by="id"
                             :options="taxes"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getTaxes($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('tax_report.from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('tax_report.to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>


              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!-- End Filter -->
    <div class="card card-custom">
      <div class="card-body">
        <div class="mt-3">
          <div class="row mb-10">
            <div class="col-md-12 mt-10" id="summaryTableDetailsTitle">
              <h4 class="text-center">{{ $t('MENU.tax_declaration_report') }}</h4>
              <h5 class="text-center" v-if="filters.from_date && filters.to_date">{{ $t('from') }} {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
            </div>

          </div>
          <div class="row mb-10">
            <div class="col-12">
              <b-tabs content-class="mt-3">
<!--                <b-tab :title="$t('summery')" active>-->
<!--                  <div class="table-responsive">-->
<!--                    <div class="row justify-content-end p-4">-->
<!--                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">-->
<!--                        <button class="dropdown-item" @click="convertTableToExcelReport('summaryTable')">-->
<!--                          <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                        </button>-->
<!--                        <button class="dropdown-item" @click="printData('summaryTable')">-->
<!--                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>-->
<!--                        </button>-->
<!--                      </b-dropdown>-->
<!--                    </div>-->
<!--                    <table class="table table-bordered" id="summaryTable">-->
<!--                      <tbody>-->
<!--                      <tr>-->
<!--                        <th>{{ $t('tax_report.tax') }}</th>-->
<!--                        <th>{{ $t('tax_report.taxable_amount') }}</th>-->
<!--                        <th>{{ $t('tax_report.taxes') }}</th>-->
<!--                      </tr>-->
<!--                      <template v-for="(row, index) in data_summery">-->
<!--                        <tr>-->
<!--                          <td colspan="3">{{ row.tax_name }}</td>-->
<!--                        </tr>-->
<!--                        <tr  v-for="(row2, index2) in row.items" :key="'summery_'+uniqueKey()">-->
<!--                          <td>{{ row2.label }}</td>-->
<!--                          <td>{{ row2.taxable_amount }}</td>-->
<!--                          <td>{{ row2.taxes }}</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td>{{ $t('tax_report.total') }}</td>-->
<!--                          <td>{{ row.total_taxable_amount ? row.total_taxable_amount : '0' }}</td>-->
<!--                          <td>{{ row.total_taxes ? row.total_taxes : '0' }}</td>-->
<!--                        </tr>-->
<!--                      </template>-->

<!--                      </tbody>-->
<!--                      <tfoot>-->
<!--                      <tr>-->
<!--                        <th>{{ $t('tax_report.total') }}</th>-->
<!--                        <td>{{ summery_total_sum_taxable_amount ? summery_total_sum_taxable_amount : 0 }}</td>-->
<!--                        <td>{{ summery_total_sum ? summery_total_sum : 0 }}</td>-->
<!--                      </tr>-->
<!--                      </tfoot>-->
<!--                    </table>-->
<!--                  </div>-->
<!--                </b-tab>-->
                <b-tab :title="$t('details')" active>
                  <div class="table-responsive">
                    <div  class="row justify-content-end p-4">
                      <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
                        <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                          <i class="la la-file-excel"></i>{{ $t('excel') }}
                        </button>
                        <button class="dropdown-item" @click="printData('DetailsTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table class="table table-bordered" id="DetailsTable">
                      <tbody>
                      <tr>
                        <th colspan="4">{{$t('tax_declaration_report.sales')}}</th>
                      </tr>
                      <tr>
                        <th>{{$t('tax_declaration_report.tax')}}</th>
                        <th>{{$t('tax_declaration_report.sales')}}</th>
                        <th>{{$t('tax_declaration_report.edits')}}</th>
                        <th>{{$t('tax_declaration_report.tax_due')}}</th>
                      </tr>

                      <template v-for="(row, index) in data_details.sales">
                        <tr>
                          <th>{{row.tax_name}}</th>
                          <th>{{row.invoice_total}}</th>
                          <th>{{row.credit_total}}</th>
                          <th>{{row.tax_value}}</th>
                        </tr>
                      </template>
                      <tr>
                        <th colspan="4">{{$t('tax_declaration_report.purchases')}}</th>
                      </tr>
                      <tr>
                        <th>{{$t('tax_declaration_report.tax')}}</th>
                        <th>{{$t('tax_declaration_report.purchases')}}</th>
                        <th>{{$t('tax_declaration_report.edits')}}</th>
                        <th>{{$t('tax_declaration_report.payable_tax')}}</th>
                      </tr>
                      <template v-for="(row, index) in data_details.purchase">
                        <tr>
                          <th>{{row.tax_name}}</th>
                          <th>{{row.invoice_total}}</th>
                          <th>{{row.credit_total}}</th>
                          <th>{{row.tax_value}}</th>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <th colspan="3">{{ $t('tax_report.total') }}</th>
                        <td>{{ details_total_sum_taxable_amount ? details_total_sum_taxable_amount : 0 }}</td>
                      </tr>
                      </tfoot>

                    </table>
<!--                    <table class="table table-bordered" id="DetailsTable">-->
<!--                      <tbody>-->
<!--                      <tr>-->

<!--                        <th>{{ $t('tax_report.id') }}</th>-->
<!--                        <th>{{ $t('tax_report.financier') }}</th>-->
<!--                        <th>{{ $t('tax_report.date') }}</th>-->
<!--                        <th>{{ $t('tax_report.item') }}</th>-->
<!--                        <th>{{ $t('tax_report.description') }}</th>-->
<!--                        <th>{{ $t('tax_report.taxable_amount') }}</th>-->
<!--                        <th>{{ $t('tax_report.taxes') }}</th>-->
<!--                      </tr>-->
<!--                      <template v-for="(row, index) in data_details">-->
<!--                        <tr>-->
<!--                          <td colspan="7">{{ row.tax_name }}</td>-->
<!--                        </tr>-->
<!--                        <template v-for="(row2, index2) in row.items">-->
<!--                          <tr>-->
<!--                            <td colspan="7">{{ row2.label }}</td>-->
<!--                          </tr>-->
<!--                        <tr  v-for="(row3, index3) in row2.list" :key="'tax2_'+ uniqueKey()">-->
<!--                          <td>{{ row3.id }}</td>-->
<!--                          <td>{{ row3.financier }}</td>-->
<!--                          <td>{{ row3.date }}</td>-->
<!--                          <td>{{ row3.item }}</td>-->
<!--                          <td>{{ row3.description }}</td>-->
<!--                          <td>{{ row3.taxable_amount }}</td>-->
<!--                          <td>{{ row3.taxes }}</td>-->
<!--                        </tr>-->
<!--                        <tr>-->
<!--                          <td colspan="5">{{ $t('tax_report.total') }}</td>-->
<!--                          <td>{{ row2.total_taxable_amount ? row2.total_taxable_amount : '0' }}</td>-->
<!--                          <td>{{ row2.total_tax ? row2.total_tax : '0' }}</td>-->
<!--                        </tr>-->

<!--                        </template>-->
<!--                        <tr>-->
<!--                          <td colspan="5">{{ $t('tax_report.total') }}</td>-->
<!--                          <td>{{ row.total_taxable_amount ? row.total_taxable_amount : '0' }}</td>-->
<!--                          <td>{{ row.total_tax ? row.total_tax : '0' }}</td>-->
<!--                        </tr>-->
<!--                      </template>-->

<!--                      </tbody>-->
<!--                      <tfoot>-->
<!--                      <tr>-->
<!--                        <th colspan="5">{{ $t('tax_report.total') }}</th>-->
<!--                        <td>{{ details_total_sum_taxable_amount ? details_total_sum_taxable_amount : 0 }}</td>-->
<!--                        <td>{{ details_total_sum ? details_total_sum : 0 }}</td>-->
<!--                      </tr>-->
<!--                      </tfoot>-->
<!--                    </table>-->
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>

      </div>
    </div>
    <!--end::supplier-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import TableToExcel from "@linways/table-to-excel";

export default {
  name: "index-tax-report",
  data() {
    return {
      mainRoute: '/reports/taxes',
      mainRouteSummery: '/reports/taxs/report/list',
      mainRouteDependency: 'base/dependency',

      currency_name: null,
      taxes: [],
      tax: null,

      data_report_details: [],
      details_total_sum_taxable_amount: 0,
      details_total_sum: 0,
      total_taxes: 0,
      summery_total_sum_taxable_amount: 0,
      summery_total_sum: 0,
      // sales_list: [],
      // purchase_list: [],
      data_summery: [],

      data_details: [],
      filters: {
        tax_id: null,
        from_date: null,
        to_date: null,
      },
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  watch: {
    tax: function (val) {
      if (val) {
        this.filters.tax_id = val.id
      } else {
        this.filters.tax_id = null;
      }
    }
  },
  beforeMount() {
    this.getAuthUser();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.accounting_reports'), route: '/reports/accounting-reports'}, {title: this.$t("MENU.tax_declaration_report")}]);
  },
  methods: {
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },
    doFilter() {
      // this.getReportSummery();
      this.getReportDetails();

    },
    resetFilter() {
      this.filters.tax_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.tax = null;
      this.doFilter();

    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },

    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },

    uniqueKey(){
      return  Math.floor(Math.random() * 10000000000) + '_'+ Date.now();
    },

    /*****
     * details
     */

    getReportDetails() {


      ApiService.get(this.mainRoute, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_details = response.data.data;
        this.details_total_sum_taxable_amount = response.data.data.tax_total;
      });
    },
    getReportSummery() {

      ApiService.get(this.mainRouteSummery, {params: {...this.filters, page: (this.page ? this.page : 1)}}).then((response) => {
        this.data_summery = response.data.data;
        this.summery_total_sum_taxable_amount = response.data.data.tax_total;
      });
    },
    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'tax report');
      TableToExcel.convert(document.getElementById(tableId), {
        name: this.$t("MENU.tax_report") + '.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'tax report');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('summaryTableDetailsTitle');
      let newWin = window.open("");
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        newWin.close();
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
